import { Box } from '@pancakeswap/uikit'
import { styled } from 'styled-components'

export const StyledSwapContainer = styled(Box)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  width: 100%;
  padding: 0;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 12px;
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  min-width: 328px;
`
